import React from 'react';
import Metadata from '../components/Metadata';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { StyledContainer } from '../layouts/styles';
import Section from '../components/Section';

const StyledImageTextContainer = styled.div`
  p {
    font-size: 1.5rem;
  }

  @media (min-width: 900px) {
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr 1fr;

    .gatsby-image-wrapper {
      width: 100%;
    }
  }
`;

const StyledSubHeadline = styled.h3`
  margin-bottom: 1rem;
`;

const StyledList = styled.ul`
  margin-bottom: 2rem;
`;

function Jobs() {
  return (
    <>
      <Metadata title='Jobs' />
      <StyledContainer>
        <h1 style={{ marginBottom: '2rem' }}>Jobs</h1>
        <Section padding={0}>
          <StyledImageTextContainer>
            <div>
              <h2 style={{ marginBottom: '1rem' }}>Arbeiten mit Rückenwind</h2>
              <p>
                Du interessierst dich für unsere Arbeit und möchtest dich
                beruflich weiterentwickeln?
              </p>
            </div>
            <StaticImage
              style={{ marginBottom: '2rem' }}
              src='../images/Jobs.png'
              alt='Mehrere Daumen nach oben'
            />
          </StyledImageTextContainer>
        </Section>

        <Section>
          <p>
            Da wir regelmäßig nach Verstärkung für unser Team suchen, sende uns
            doch gerne deine Initiativbewerbung.
          </p>
          <p>
            Wir bieten eine Spezialisierung auf unterschiedliche Altersgruppen
            mit unterschiedlichem Förderbedarf an:
          </p>
          <StyledList>
            <li>Altersgruppe 2 Jahre – Schuleintritt</li>
            <li>
              Schulkinder (Förderschwerpunkt Lernen / geistige Entwicklung)
            </li>
            <li>Schulkinder (Förderschwerpunkt sozial-emotional)</li>
            <li>Jugendliche und junge Volljährige</li>
          </StyledList>
          <p>
            Dabei bekommst du bei uns die Mittel und Ressourcen zur Verfügung
            gestellt, um die Kinder und Jugendlichen wirksam zu fördern. Zu
            deinen Aufgaben gehören unter anderem die Durchführung der
            Therapieeinheiten, die Mitwirkung an den Förderplänen, Entwicklungs-
            und Förderdiagnostik sowie die Zusammenarbeit mit den
            förderunterstützenden Personen.
          </p>
          <StyledSubHeadline>Voraussetzungen:</StyledSubHeadline>
          <StyledList>
            <li>
              Du hast einen abgeschlossenen (Fach-)Hochschulabschluss in einem
              einschlägigen Bereich (z.B. Pädagogik, Soziale Arbeit,
              Sozialpädagogik, Heilpädagogik, Psychologie o.ä.).
            </li>
            <li>
              Du hast Freude an der Zusammenarbeit mit Kindern und Jugendlichen
              und der Arbeit im Team.
            </li>
            <li>
              Du bist motiviert, dich methodisch und professionell
              weiterzuentwickeln und fortzubilden.
            </li>
            <li>
              Du hast schon Erfahrungen in der Arbeit mit Kindern oder
              Jugendlichen (bevorzugt, mit Kindern und Jugendlichen mit
              Autismus, jedoch nicht ausschlaggebend).
            </li>
          </StyledList>
          <StyledSubHeadline>Wir bieten:</StyledSubHeadline>
          <StyledList>
            <li>
              eine Vollzeitstelle (37,5 St./Woche) mit unbefristetem
              Arbeitsvertrag
            </li>
            <li>
              gründliche Einarbeitung und Begleitung durch Intervision und
              Supervision
            </li>
            <li>regelmäßige Möglichkeiten zu Fort- und Weiterbildungen</li>
            <li>
              eine Spezialisierung auf die Förderung von autistischen
              Kindern/Jugendlichen
            </li>
            <li>
              Aufstiegsmöglichkeiten durch die Erweiterung des Aufgaben- und
              Kompetenzfeldes
            </li>
            <li>
              die Zusammenarbeit mit enthusiastischen, motivierten und
              hilfsbereiten Teamkollegen und -kolleginnen
            </li>
            <li>betriebliche Altersvorsorge</li>
            <li>gute Erreichbarkeit, da direkt am HBF Duisburg</li>
          </StyledList>
          <StyledSubHeadline>Kontakt:</StyledSubHeadline>
          <p>
            Es freut uns, wenn wir dein Interesse wecken konnten. Sende dein
            Anschreiben und deinen Lebenslauf inkl. Qualifikationsnachweisen
            bitte an
          </p>
          <p>
            <a href='mailto:info@lernen-rueckenwind.de'>
              info@lernen-rueckenwind.de
            </a>
          </p>
          <p>
            Deine Ansprechpartnerin ist <b>Sabrina Liebich</b>.
          </p>
          <p>Wir freuen uns darauf, dich kennenzulernen!</p>
        </Section>
      </StyledContainer>
    </>
  );
}

export default Jobs;
